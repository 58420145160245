import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import onClickOutside from 'react-onclickoutside'

const NavMenu = ({ close }) => {
  NavMenu.handleClickOutside = close

  return (
    <div
      css={theme => css`
        position: relative;
        width: 100%;
        @media (min-width: ${theme.breakpoints.medium}px) {
          max-width: 400px;
        }
        border-radius: ${theme.radius}px;
        background: #fff;
        color: ${theme.colors.charcoal};
        padding: 40px 24px 24px;
        box-shadow: 0 12px 32px rgba(0, 0, 0, 0.2);
      `}
    >
      <button
        onClick={close}
        css={css`
            padding: 0;
            border: 0;
            height: 34px;
            width: 34px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            font-size: 2.3rem;
            line-height: 1;
            text-align: center;
            border-radius: 999px;
            position: absolute;
            top: 12px;
            right: 12px;
            transition: background: .3s;
            &:hover {
              background: rgba(0, 0, 0, 0.4);
            }
          `}
      >
        &times;
      </button>
      <ul
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;
          font-size: 1.9rem;
          font-weight: 400;

          > li a {
            line-height: 1.4;
            padding: 8px;
            display: block;
            text-decoration: none;
            margin-top: 4px;
            margin-bottom: 4px;
          }

          > li:not(:first-of-type) a {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }
        `}
      >
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a href="/no-loss-properties-brochure.pdf" download="No Loss Properties Brochure.pdf">
            Download Brochure
          </a>
        </li>
        <li>
          <Link to="/faqs">Frequently Asked Questions</Link>
        </li>
        <li>
          <a href="mailto:hello@nolossproperties.com">Email Enquiry</a>
        </li>
        {/* <li>
          <Link to="/">Enquiry Form</Link>
        </li>
        <li>
          <Link to="/important-information">Important Information</Link>
        </li> */}
      </ul>
    </div>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => NavMenu.handleClickOutside,
}

export default onClickOutside(NavMenu, clickOutsideConfig)
