import { Link } from 'gatsby'
import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import nlpLogoSrc from '../images/nlp-logo.svg'
import roofsMotifSrc from '../images/roofs-motif.svg'
import Nav from './Nav'

const Header = () => (
  <header
    css={theme => css`
      padding-top: 24px;
      color: #fff;
      position: relative;
      background: url(${roofsMotifSrc}) calc(100% + 1px) calc(100% + 1px)
        no-repeat ${theme.colors.primary};

      background-size: auto 190px;
      padding-bottom: 90px;
      @media (min-width: ${theme.breakpoints.medium}px) {
        background-size: auto 230px;
        padding-bottom: 40px;
      }
    `}
  >
    <div className="container">
      <nav
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
        `}
      >
        <Nav />
        <a
          href="mailto:hello@nolossproperties.com"
          className="button button--outline"
          css={css`
            font-size: 1.7rem;
            padding-top: 0.35em;
            padding-bottom: 0.25em;
          `}
        >
          Email Enquiry
        </a>
        {/* <Link
          to="/"
          className="button button--outline"
          css={css`
            font-size: 1.7rem;
            padding-top: 0.35em;
            padding-bottom: 0.25em;
          `}
        >
          Enquire
        </Link> */}
      </nav>

      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <div
          css={css`
            text-align: center;
            width: 100%;
          `}
        >
          <Link
            to="/"
            css={theme => css`
              display: block;
              width: 340px;
              min-width: 200px;
              max-width: 80%;
              margin: 32px auto 0;
              @media (min-width: ${theme.breakpoints.medium}px) {
                margin-top: 0;
              }
            `}
          >
            <img
              src={nlpLogoSrc}
              alt="No Loss Properties logo"
              style={{ display: 'block' }}
            />
          </Link>
          <Strapline>
            The <strong>smarter</strong> way to buy property
          </Strapline>
        </div>
      </div>
    </div>
  </header>
)

export default Header

const Strapline = styled.p`
  font-size: 2.8rem;
  font-weight: 300;
  margin: 16px auto 32px;
  line-height: 1.2;
  max-width: 240px;

  @media (min-width: ${p => p.theme.breakpoints.medium}px) {
    max-width: none;
  }

  strong {
    font-weight: 600;
  }
`
