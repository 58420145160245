import React from 'react'
import { css } from '@emotion/core'

export default () => {
  return (
    <footer
      css={css`
        text-align: center;
        padding-top: 64px;
        padding-bottom: 64px;
      `}
    >
      <div className="container">
        <div>© {new Date().getFullYear()}. All rights reserved.</div>
      </div>
    </footer>
  )
}
