import { css } from '@emotion/core'
import { theme } from '../config'

export default css`
  button,
  .button {
    &:not(:disabled) {
      cursor: pointer;
    }
    &:focus {
      outline: 0;
      background-color: ${theme.colors.primaryTint}88;
    }
  }
  .button {
    background: ${theme.colors.primaryTint};
    border: 2px solid ${theme.colors.tint1}40;
    border-radius: 8px;
    color: #fff;

    text-transform: uppercase;
    text-shadow: 0 2px 3px ${theme.colors.primary}E6;
    text-decoration: none;
    font-weight: 600;

    font-size: 2rem;
    letter-spacing: 0.04em;

    padding: 0.4em 0.8em;
    white-space: nowrap;

    display: inline-block;
    min-width: 6em;
    text-align: center;

    &:hover {
      color: inherit;
    }

    &--outline {
      border-color: ${theme.colors.pimaryTint};
      background-color: ${theme.colors.primaryTint}33;
      &:focus {
        background: ${theme.colors.primaryTint}bb;
      }
    }

    &--expand {
      display: block;
    }
  }
`
