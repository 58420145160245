import React, { useState } from 'react'
import { Global, css } from '@emotion/core'
import hamburgerIconSrc from '../images/icons/hamburger.svg'
import NavMenu from './NavMenu'
import { motion, AnimatePresence } from 'framer-motion'

const Nav = () => {
  const [visible, setVisible] = useState(false)
  const toggle = () => setVisible(!visible)

  return (
    <>
      <button
        onClick={toggle}
        css={theme => css`
          background: 0;
          border: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48px;
          width: 48px;
          border-radius: 8px;
          margin-left: -12px;
          transition: background 0.2s;
          &:focus {
            outline: none;
            ${!visible && `background: ${theme.colors.primaryTint}bb;`}
          }
        `}
      >
        <img src={hamburgerIconSrc} alt="Menu" />
      </button>
      {visible && (
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
      )}
      <AnimatePresence>
        {visible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ zIndex: 100 }}
          >
            <motion.div
              transition={{ delay: 0.1 }}
              css={css`
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.5);
                z-index: 200;
              `}
            />
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              css={css`
                position: absolute;
                top: -4px;
                left: -16px;
                right: -16px;
                transform-origin: 16px 16px;
                z-index: 200;
              `}
            >
              <NavMenu visible={visible} close={() => setVisible(false)} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Nav
