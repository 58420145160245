import React from 'react'
import { Global, css } from '@emotion/core'
import { theme } from '../config'

import 'normalize.css'
import Header from './Header'
import Footer from './Footer'
import typographicStyles from '../styles/typographyStyles'
import buttonStyles from '../styles/buttons'

const baseStyles = css`
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .container {
    max-width: ${theme.breakpoints.large}px;
    margin: 0 auto;

    &:not(.collapse) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
`

const Layout = ({ children }) => {
  return (
    <>
      <Global styles={[baseStyles, typographicStyles, buttonStyles]} />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
