import { css } from '@emotion/core'
import { theme } from '../config'

const typographicStyles = css`
  html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: azo-sans-web, Helvetica, sans-serif;
    font-display: fallback;
    font-size: 1.7rem;
    line-height: 1.5;
    color: ${theme.colors.charcoal};
  }

  .heading {
    font-weight: 300;

    line-height: 1.3;
    strong {
      font-weight: 500;
    }

    margin-top: 16px;
    margin-bottom: 16px;

    &--1 {
      font-size: 3rem;
      @media (min-width: ${theme.breakpoints.medium}px) {
        font-size: 4rem;
      }
    }

    &--2 {
      font-size: 2.4rem;
      @media (min-width: ${theme.breakpoints.medium}px) {
        font-size: 3rem;
      }
    }

    &--3 {
      font-size: 2.7rem;
      @media (min-width: ${theme.breakpoints.medium}px) {
        font-size: 2.8rem;
      }
    }

    &--4 {
      font-size: 2.1rem;
      @media (min-width: ${theme.breakpoints.medium}px) {
        font-size: 2.3rem;
      }
    }
  }

  a {
    color: ${theme.colors.primary};
    transition: color 0.15s;

    &:visited {
      color: inherit;
    }

    &:hover {
      color: ${theme.colors.primaryTint};
    }
  }
`

export default typographicStyles
